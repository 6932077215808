/* eslint no-extend-native: 0 */
// Example taken from https://github.com/zeit/next.js/tree/canary/examples/with-polyfills
// core-js comes with Next.js. So, you can import it like below
import assign from 'core-js/es/object/assign';

// Replacement for Babel/Polyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Add your polyfills
// This files runs at the very beginning (even before React and Next.js core)

if (typeof Object.assign !== 'function') {
  Object.assign = assign;
}
